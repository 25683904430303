// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from "react";

import { FIREBASE_APP_CONFIG, TOKEN_NAME } from "../constants";

import { firebase } from "./firebase";
import "firebase/firestore";
import "firebase/auth";
import { User } from "@firebase/auth-types";
import cookie from "js-cookie";
import * as Sentry from "@sentry/node";
import pick from "lodash/pick";

const authContext = createContext(null);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes. d

export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state

const useProvideAuth = () => {
  const [user, setUser] = useState<User>(null);
  const [emailVerifySent, setEmailVerifySent] = useState<boolean>(false);
  // Wrap any Firebase methods we want to use making sure ...

  // ... to save the user to state.

  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signup = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        setUser(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(null);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .set(
            pick(user, [
              "id",
              "email",
              "displayName",
              "emailVerified",
              "phoneNumber",
              "photoURL"
            ])
          );
        if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
          Sentry.configureScope(function(scope) {
            scope.setUser(user.toJSON());
          });
        }
        if (user.email && !user.emailVerified) {
          setEmailVerifySent(true);
          await user.sendEmailVerification();
          await firebase.auth().signOut();
        } else {
          const token = await user.getIdToken();
          cookie.set(TOKEN_NAME, token, { expires: 1 });
          setUser(user);
        }
      } else {
        cookie.remove(TOKEN_NAME);
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Return the user object and auth methods

  return {
    user,
    emailVerifySent,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
};
