// import admin from "firebase-admin";
import { FIREBASE_APP_CONFIG } from "../constants";
import * as fb from "firebase/app";
import "firebase/firestore";
import { Fuego } from "@nandorojo/swr-firestore";

export const firebase = !fb.apps.length
  ? fb.initializeApp(FIREBASE_APP_CONFIG)
  : fb.app();

export const docFetcher = async docKey => {
  try {
    const doc = await (await db.doc(docKey).get()).data();
    return doc;
  } catch (error) {
    console.error(error);
  }
};

export const db = firebase.firestore();
export const fuego = new Fuego(FIREBASE_APP_CONFIG);
export const fieldValue = fb.firestore.FieldValue;
export const Timestamp = fb.firestore.Timestamp;
// export const increment = firebase.database.ServerValue.increment(1);
